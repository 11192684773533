@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-brand: #002A51;
  --secondary-brand: #C7FF00;
  --bg-primary: #FFFFFF;
  --bg-secondary: #E0E7ED;
  --primary-text-color: #002A51;
}

@font-face {
  font-family: 'Chakra Petch';
  src: url('assets/fonts/ChakraPetch-Regular.ttf') format('truetype');
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Chakra Petch', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-text-color);
  background-color: var(--bg-primary);
}

code {
  font-family: 'Chakra Petch', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer components {
  .hexoid {
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  }
  .hexoid-inner {
    clip-path: polygon(50% 5%, 95% 27.5%, 95% 72.5%, 50% 95%, 5% 72.5%, 5% 27.5%);
  }
  .clipped-corners {
    clip-path: polygon(0.5rem 0, calc(100% - 0.5rem) 0, 100% 0.5rem, 100% calc(100% - 0.5rem), calc(100% - 0.5rem) 100%, 0.5rem 100%, 0 calc(100% - 0.5rem), 0 0.5rem);
  }
}

/* Responsive Typography */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  body {
    font-size: 16px;
  }
}

@media (min-width: 1025px) {
  body {
    font-size: 18px;
  }
}